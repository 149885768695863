import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";

import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

export default function StripeCheckoutForm({order, onShowError}) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);

  const handleError = (msg) => {
    console.error('stripe payment error', msg);
    onShowError?.();
  }
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!stripe || !elements || !order.id) {
      return;
    }
    setSubmitting(true);
    elements.getElement("payment").update({readOnly: true});
    await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_HOST}/orders/${order.id}`
      }
    }).then((result) => {
      if (result.error) {
        handleError(result.error);
      }
    }).catch((err) => {
      handleError(err);
    });
    setSubmitting(false);
    elements.getElement("payment").update({readOnly: false});
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <PaymentElement id="stripe-payment-element" />
        { submitting ?
          <CircularProgress size={28} color="primary" sx={{mt: '16px'}} />
          :
          <Button onClick={handleSubmit} type="submit" size="large" variant="contained" sx={{mt: '32px'}}>Complete Order</Button>
        }
      </Box>
    </form>
  );
}