import { Box, Typography } from "@mui/material";
import React from "react";

export default function PrivacyPolicyPage() {
  const StyledLink = ({href, children}) => {
    return <a style={{color:"#128080"}} href={href}>{children}</a>
  };
  return (
    <Box sx={{p: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Box sx={{maxWidth: '800px', textAlign: 'justify'}}>
        <Typography variant="h3" textAlign='left'>Privacy Policy</Typography>
        <Typography variant="body2" sx={{mt: '16px'}}>Last Updated: December 30, 2023</Typography>
        <Typography variant="body2" sx={{mt: '16px'}}>We appreciate your trust in our products and services. In order to provide the CheckMyChess marketplace platform through our website (checkmychess.com and all subdomains thereof such as app.checkmychess.com), and through the services we provide (collectively, the website, and services referred to as our "Site"), and continue to make them better, Check My Chess LLC. ("CheckMyChess," "we," "us," or "our") collects information from you.</Typography>
        <Typography variant="body2" sx={{mt: '16px'}}>This Privacy Policy explains how CheckMyChess collects, stores, uses, transfers, and discloses your personal information. This policy should be read alongside, and in addition to, our <StyledLink href="/terms">Terms of Service</StyledLink>.</Typography>
        <ol type="1" style={{marginTop: '16px', paddingInline: '16px'}}>
          <li>
            <Typography variant="h5">Scope of CheckMyChess Privacy Policy</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>This privacy policy applies only to the actions of CheckMyChess and Users with respect to the Site. It does not extend to any websites that can be accessed from our Site including, but not limited to, the payment provider selected by CheckMyChess, and any links we may provide to social media websites.  You should review any and all third-party website privacy policies (such as, but not limited to, the payment provider's privacy policy) before providing your personal information, as they are responsible for personal information (as a data controller if you're in the UK or EEA) in their own right, and their privacy practices may vary from ours.</Typography>
          </li>
          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Data</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>We may collect the following information ("Data"), which includes personal information, from you:
              <ol type="a">
                <li>Name;</li>
                <li>Age;</li>
                <li>Address;</li>
                <li>Contact Information such as email addresses and telephone numbers;</li>
                <li>IP address (automatically collected);</li>
                <li>Web browser type and version (automatically collected);</li>
              </ol>
              in each case, in accordance with this privacy policy.
            </Typography>
          </li>
          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Data Collection</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>We collect Data in the following ways:
              <ol type="a">
                <li>data is given to us by you; and</li>
                <li>data is collected automatically.</li>
              </ol>
              <ul>
                <li>
                  <strong>Data given to us by you</strong>
                  <br/>CheckMyChess will collect your Data in a number of ways, for example:
                  <ol type="i">
                    <li>when you contact us through the Site, by telephone, post, e-mail or through any other means;</li>
                    <li>when you make payments to us;</li>
                    <li>when you register for a user account</li>
                    <li>when you elect to receive marketing communications from us;</li>
                  </ol>
                  in each case, in accordance with this privacy policy.
                </li>
                <li>
                  <strong>Data collected automatically</strong>
                  <br/>To the extent that you access the Site, we will collect your Data automatically, for example:
                  <ol type="i">
                    <li>we automatically collect some information about your visit to the Site. This information helps us to make improvements to the Site content and navigation, and includes your IP address, the date, times and frequency with which you access the Site and the way you use and interact with its content.</li>
                    <li>we will collect your Data automatically via cookies, in line with the cookie settings on your browser.</li>
                  </ol>
                </li>
              </ul>
            </Typography>
          </li>
          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Data Usage</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>
              Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Site. Specifically, Data may be used by us for the following reasons:
              <ol type="a">
                <li>internal record keeping;</li>
                <li>improvement of our services;</li>
                <li>transmission by email of marketing materials that may be of interest to you;</li>
              </ol>
              in each case, in accordance with this privacy policy.
              We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If you are not satisfied with this, you have the right to object in certain circumstances (see Section 7 below).
              For the delivery of direct marketing to you via e-mail, we'll need your consent, whether via an opt-in or soft-opt-in:
              <ol type="i">
                <li>soft opt-in consent is a specific type of consent which applies when you have previously engaged with us (for example, you contact us to ask us for more details about a particular service, and we are marketing similar services). Under "soft opt-in" consent, we will take your consent as given unless you opt-out.</li>
                <li>for other types of e-marketing, we are required to obtain your explicit consent; that is, you need to take positive and affirmative action when consenting by, for example, checking a tick box that we'll provide.</li>
                <li>if you are not satisfied about our approach to marketing, you have the right to withdraw consent at any time. To find out how to withdraw your consent, see Section 7 below.</li>
              </ol>
            </Typography>
          </li>
          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Data Security</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>We will use technical and organizational measures to safeguard your Data. Technical and organizational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorized access to your Data, please let us know immediately by emailing <StyledLink href="mailto:info@checkmychess.com">info@checkmychess.com</StyledLink>.</Typography>
          </li>
          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Data Retention</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the period necessary to fulfill the purposes outlined in this privacy policy or until you request that the Data be deleted.</Typography>
          </li>
          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Privacy Rights</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>
              <ol type="a">
                <li>Exercising Your Rights
                  <br/>If you want to exercise any of your rights included in this Privacy Policy, please contact us by emailing info@checkmychess.com or by post.
                </li>
                <li>‍Cookie and Marketing Preferences
                  <br/>To stop receiving marketing email communications from CheckMyChess, click on the "unsubscribe" link in the footer of the email. We may continue to send you email communications relating to your account or use of the Site even if you have unsubscribed. To stop receiving promotional text messages, text STOP, END, CANCEL, UNSUBSCRIBE, or QUIT to our short code. To manage your cookie preferences, use the settings in your web browser.
                </li>
                <li>Google Analytics
                  <br/>CheckMyChess uses Google Analytics. Google Analytics uses cookies and similar technologies to collect and analyze information about the use of the Site and report on activities and trends. This service may also collect information regarding the use of other websites, apps, and online resources. For information from Google about opting out, <StyledLink href="https://support.google.com/ads/answer/2662922">click here</StyledLink>, and to download the Google Analytics opt-out browser add-on from Google, <StyledLink href="https://tools.google.com/dlpage/gaoptout">click here</StyledLink>.
                </li>
              </ol>
            </Typography>
          </li>
          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Supplemental Privacy Rights</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>
              When using the Site and submitting Data to us, you may have certain rights under Nevada and the State of California.
              <ol type="a">
                <li>Nevada Privacy Rights
                  <br/>We do not sell personal information for monetary consideration under Nevada law. Nevada residents have the right to submit a verified request directing us not to sell their personal information. If you are a Nevada resident, and would like to submit such a request, please do so by emailing info@checkmychess.com
                </li>
                <li>California Shine the Light law
                  <br/>CheckMyChess does not share Personal Information (as defined by California Civil Code §1798.83) with other people or non-affiliated businesses for their direct marketing purposes.
                </li>
                <li>California Consumer Privacy Act
                  <ol type="i" style={{paddingInline: '16px'}}>
                    <li>Under the California Consumer Privacy Act of 2018 ("CCPA"), California consumers have the right to request:
                      <ol type="I" style={{paddingInline: '16px'}}>
                        <li><strong>Deletion:</strong> You can request that we delete the Personal Information we have about you, subject to certain exceptions;</li>
                        <li><strong>Access:</strong> You can request that we disclose to you the categories of Personal Information we collected about you, the categories of sources from which we collected the Personal Information, the categories of Personal Information we sold or disclosed, our business or commercial purpose for collecting and selling the Personal Information, the categories of third parties with whom we shared the Personal Information, and the specific pieces of Personal Information we have about you.</li>
                        <li><strong>Sales of Personal Information:</strong> Based on our understanding of the CCPA, we believe we are not selling your Personal Information as defined under the CCPA.</li>
                      </ol>
                    </li>
                    <li>California consumers also have the right not to receive discriminatory treatment if they exercise the rights listed above.</li>
                    <li>When you make a request, we may require that you provide information and follow procedures so that we can verify the request and your jurisdiction before responding to it. The verification steps we take may differ depending on the request you make. We will match the information that you provide in your request to information we already have on file to verify your identity. If we are able to verify your request, we will process it. If we cannot verify your request, we may ask you for additional information to help us verify your request. Consumers will be required to submit their first and last name and email address and may also be asked to provide their telephone number or address so that we can verify the request. Please provide as much of the requested information as possible to help us verify the request. We will only use the information received in a request for the purposes of responding to the request.</li>
                    <li>California law permits California consumers to use an authorized agent to make privacy rights requests. We require the authorized agent to provide us with proof of the California consumer's written permission (for example, a power of attorney) that shows the authorized agent has the authority to submit a request for the California consumer. An authorized agent must follow the process described here in this privacy policy to make a request. The authorized agent must also verify his/her own identity. We will confirm the agent's authority with the California consumer about whom the request was made.</li>
                  </ol>
                </li>
              </ol>
            </Typography>
          </li>

          <li style={{marginTop: '16px'}}>
            <Typography variant="h5">Changes to this Privacy Policy</Typography>
            <Typography variant="body2" sx={{mt: '16px'}}>CheckMyChess reserves the right to change this privacy policy as we may deem necessary or as may be required by law. When we make changes, we will post the revised Privacy Policy on our Site and update the "Last Updated" date at the top of this Privacy Policy. The revised Privacy Policy will be effective immediately if you accept it (for example, by agreeing when you create an account or login to an existing account, or using or continuing to use our Site after the revised Privacy Policy has been posted); otherwise, it will be effective 30 days after posting.</Typography>
          </li>
        </ol>
      </Box>
    </Box>
  );
}
