import React, { useContext, useEffect, useState } from "react";
import { Box, Card, IconButton, Slide, Snackbar, SnackbarContent } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { authContext } from "contexts/AuthContext";
import ErrorPage from "pages/error/ErrorPage";
import { createStripePaymentIntent, loadOrder } from "helpers/apiHelpers";
import LoadingSpinner from "components/LoadingSpinner";
import OrderSnapshot from "components/OrderSnapshot";
import CloseIcon from '@mui/icons-material/Close';
import PaypalCheckoutForm from "components/PaypalCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "components/StripeCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";

export default function CheckoutPage() {
  const { user, authenticating } = useContext(authContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();
  const [errorToastOpen, setErrorToastOpen] = useState(false);

  // these are specific to coaches onboarded with stripe and may not always be set (e.g. checkout page of an order for a coach using paypal)
  const [stripe, setStripe] = useState();
  const [stripePaymentSecret, setStripePaymentSecret] = useState();

  useEffect(() => {
    if (user?.authenticated && location && searchParams) {
      const orderId = searchParams.get('orderId');
      loadOrder(orderId, true).then((order) => {
        setOrder(order);
        if (order.paymentsProvider === "stripe") {
          Promise.all([
            loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then(setStripe),
            createStripePaymentIntent(orderId).then(setStripePaymentSecret)
          ]).then(() => setLoading(false));
        } else {
          setLoading(false);
        }
      });
    }
  }, [user, authenticating, location, searchParams]);

  if (loading) {
    return <LoadingSpinner text="Preparing checkout..." />;
  }

  if (!user?.authenticated && !authenticating) {
    return <ErrorPage errorStatus={401}/>
  }

  if (order.paid) {
    navigate(`/orders/${order.id}`);
    return null;
  }

  const showPaymentError = () => {
    setErrorToastOpen(true);
  };

  const closeErrorToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorToastOpen(false);
  };

  return (
    <Box sx={{mb: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Card elevation={3} sx={{width: '90%', maxWidth: '720px', p: '16px 0', mt: '32px'}}>
        <OrderSnapshot order={order} title="Order Summary"/>
      </Card>
      <Box sx={{mt: '32px', width: '90%', maxWidth: '720px'}}>
        { order.paymentsProvider === "paypal" && 
          <PaypalCheckoutForm order={order} onShowError={showPaymentError}/>
        }
        { order.paymentsProvider === "stripe" && stripePaymentSecret && stripe &&
          <Elements options={{clientSecret: stripePaymentSecret}} stripe={stripe}>
            <StripeCheckoutForm order={order} onShowError={showPaymentError} />
          </Elements>
        }
      </Box>
      <Snackbar
          open={errorToastOpen}
          TransitionComponent={Slide}
          onClose={closeErrorToast} 
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
          <SnackbarContent message="We couldn't process your payment. Please verify your details and try again." sx={{backgroundColor: "#D32E2E"}} 
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={closeErrorToast}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
    </Box>
  );
}