import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import { capturePaypalOrder, createPaypalOrder } from "helpers/apiHelpers";
import LoadingSpinner from "./LoadingSpinner";


export default function PaypalCheckoutForm({order, onShowError}) {
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const navigate = useNavigate();

  const paypalScriptOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    merchantId: order.coach.paypalMerchantId,
    dataPartnerAttributionId: process.env.REACT_APP_PAYPAL_PARTNER_BN_CODE,
    disableFunding: "paylater",
  };
  
  const createOrder = async () => {
    return createPaypalOrder(order.id);
  };

  const showError = () => {
    setIsCheckingOut(false);
    onShowError?.();
  };
  
  const onApprove = async (paypalOrder, actions) => {
    setIsCheckingOut(true);
    return capturePaypalOrder(paypalOrder.orderID)
      .then(({paymentCompleted}) => {
        if (paymentCompleted) {
          navigate(`/orders/${order.id}`);
        } else {
          showError();
        }
      })
      .catch((err) => {
        showError();
      });
  };

  const CheckoutButtons = () => {
    const [{isPending}] = usePayPalScriptReducer();
    return isPending || isCheckingOut ? <LoadingSpinner small /> : <PayPalButtons createOrder={createOrder} onApprove={onApprove} />;
  };

  return (
    <PayPalScriptProvider options={paypalScriptOptions}>
      <CheckoutButtons />
    </PayPalScriptProvider>
  );
}

