import LoadingSpinner from "components/LoadingSpinner";
import { authContext } from "contexts/AuthContext";
import { getPaypalOnboardingLink, getStripeLink } from "helpers/apiHelpers";
import ErrorPage from "pages/error/ErrorPage";
import React, { useContext, useEffect, useState } from "react";

export default function CoachPaymentsPage() {
  const { user, authenticating, coach } = useContext(authContext);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState();

  useEffect(() => {
    const paymentsProviderNames = {
      paypal: "PayPal",
      stripe: "Stripe"
    };
    if (!authenticating && user?.authenticated) {
      if (user.isCoach) {
        setLoadingText(`Redirecting to ${paymentsProviderNames[coach.paymentsProvider]}...`);
        switch(coach.paymentsProvider) {
          case "paypal":
            if (coach.paymentsOnboardingComplete) {
              window.location.replace(process.env.REACT_APP_PAYPAL_DASHBOARD_URL);
            } else {
              getPaypalOnboardingLink(coach.id).then((link) => {
                window.location.replace(link.href);
              });
            }
            break;
          case "stripe":
            getStripeLink(coach.id).then((stripeLink) => {
              window.location.replace(stripeLink.url);
            });
            break;
          default:
            console.error(`Unexpected payments provider: ${coach.paymentsProvider}`);
            break;
        }
      } else {
        setLoading(false);
      }
    }
  }, [user, authenticating, coach]);

  if (loading) {
    return <LoadingSpinner text={loadingText} />;
  } else if (!user?.authenticated || !user.isCoach) {
    return <ErrorPage errorStatus={404} />;
  }
}